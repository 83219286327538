// Accordions
import Accordion from "accordion-js";
// Lightbox
import "../utils/fancybox";
import "jquery.marquee";
//Tabs
import { Tabs } from "../utils/tabs";

export default {
	init() {
		console.log("common-js loaded");

		// Menu Toggle
		const header = document.querySelector(".header");
		const form = document.getElementById("searchform");
		const formClose = document.getElementById("searchform__close");
		const logo = document.querySelector(".logo-overlay");
		document
			.getElementById("menu-button")
			.addEventListener("click", function () {
				header.classList.toggle("visible");
				logo.classList.toggle("visible");
			});
		document
			.getElementById("search-button")
			.addEventListener("click", function () {
				form.classList.toggle("visible");
				document.querySelector(".searchform__input").focus();
			});
		form.addEventListener("click", function () {
			form.classList.toggle("visible");
			document.querySelector(".searchform__input").blur();
		});

		$(".ticker").marquee({
			duration: 13000,
			delayBeforeStart: 0,
			direction: "left",
			duplicated: false,
			pauseOnHover: true,
		});

		// Accordions
		if (document.querySelector(".accordion-container") !== null) {
			new Accordion(".accordion-container");
		}

		if (document.querySelector(".tabs-container") !== null) {
			new Tabs({
				elem: "tabs",
				open: 0,
			});
		}

		// Galery
		$('[data-fancybox="galerie"]').fancybox({
			thumbs: {
				autoStart: true,
			},
		});
		jQuery(".icon-share").on("click", function () {
			jQuery(".icons").toggleClass("visible");
		});
	},
};
