import "jquery.marquee";
import { tns } from "tiny-slider/src/tiny-slider";

// Styles SCSS
import "../sass/home.scss";

// SVG polyfiil
import "svgxuse";

// LazyLoad
import lazyLoadInit from "./lazyload-init";

import Router from "./utils/Router";
import common from "./routes/common";

lazyLoadInit();

console.log("home-js loaded");

// Vanilla
document.addEventListener("DOMContentLoaded", function () {
	const routes = new Router({
		/** All pages */
		common,
	});
	routes.loadEvents();
});

var slider = tns({
	container: ".slider-bandeau",
	items: 1,
	autoplay: true,
	autoplayButtonOutput: false,
	nav: false,
	controls: false,
	autoplayHoverPause: true,
	autoplayTimeout: 3500,
	autoplayText: ['<i class="fad fa-play"></i>', '<i class="fad fa-pause"></i>'],
	controlsText: [
		'<i class="fal fa-chevron-left"></i>',
		'<i class="fal fa-chevron-right"></i>',
	],
});
var sliderActus = tns({
	container: ".actualites-home__slider",
	items: 1,
	autoplay: true,
	autoplayButtonOutput: false,
	nav: false,
	autoplayHoverPause: true,
	autoplayTimeout: 3500,
	autoplayText: ['<i class="fad fa-play"></i>', '<i class="fad fa-pause"></i>'],
	controlsText: [
		'<i class="fal fa-chevron-left"></i>',
		'<i class="fal fa-chevron-right"></i>',
	],
});
var sliderKiosque = tns({
	container: ".kiosque__slider",
	items: 1,
	// autoplay: true,
	autoplayButtonOutput: false,
	nav: false,
	autoplayHoverPause: true,
	autoplayTimeout: 3500,
	autoplayText: ['<i class="fad fa-play"></i>', '<i class="fad fa-pause"></i>'],
	controlsText: [
		'<i class="fal fa-chevron-left"></i>',
		'<i class="fal fa-chevron-right"></i>',
	],
});
